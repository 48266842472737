.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: var(--layout-bg);
    z-index: 99;
    transition: background-color 0.4s;
}
.headerCenter {
    position: relative;
}
.logo {
    display: inline-block;
    padding: 30px 0;
    line-height: 0;
}
.logo img {
    height: 24px;
    width: auto;
}
.headerLinks {
    position: absolute;
    top: 50%;
    right: 0;
    padding: 15px 0;
    transform: translateY(-50%);
}
.headerLinkItem {
    display: inline-block;
    padding: 15px;
    line-height: 1;
    font-weight: bold;
    vertical-align: middle;
}
.hasSubMenu>button {
    padding: 10px 0 10px 10px;
}
.hasSubMenu>* {
    vertical-align: middle;
    display: inline-block;
}
.dropdownMenu {
    padding: 40px 0 80px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: var(--layout-bg);
    font-family: 'FS Kim';
    font-weight: 500;
}
.dropdownMenu>div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.dropdownMenuContent {
    max-width: 600px;
    padding-top: 10px;
}
.dropdownText {
    font-weight: normal;
}
.dropdownMenuLinks {
    flex: 1;
    font-size: 36px;
    padding-left: 60px;
    max-width: 700px;
}
.dropdownMenuLinkItem {
    padding: 20px 40px;
    border-radius: 100px;
    display: block;
    border: 1px solid var(--layout-bg);
    line-height: 1;
    position: relative;
    padding-right: 130px;
}
.dropdownMenuLinkItem:hover {
    background-color: #304564;
    border-color: #9F9F9F;
}
.dropdownButton {
    margin-top: 30px;
}
.arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
    color: #f1f1f1;
    font-size: 18px;
    opacity: 0;
    transition: opacity 0.4s;
}
.arrow:before {
    content: '';
    position: absolute;
    right: 3px;
    margin-top: -0.5px;
    top: 50%;
    transform: translateY(-50%);
    width: 75px;
    height: 2px;
    background-color: #f1f1f1;
}
.dropdownMenuLinkItem:hover .arrow {
    opacity: 1;
}
.content {
    min-height: 100vh;
}
.contact {
    padding-right: 30px;
}
.footerWrap {
    display: flex;
    flex-direction: row;
    padding: 90px 0;
    justify-content: space-between;
}
.title {
    font-size: 26px;
    font-family: 'FS Kim';
}
.iconText {
    margin-left: 20px;
}
.locationAddress,
.icon {
    margin-top: 15px;
}
.icon,
.locations {
    white-space: nowrap;
}
.location {
    display: inline-block;
    padding: 0 30px;
    white-space: normal;
}
.links {
    border-top: 1px solid #546379;
}
.links::after {
    content: "";
    clear: both;
    display: block;
}
.footerLinks {
    float: left;
    padding: 20px 0;
}
.footerLinksMargin {
    margin: 0 -35px;
}
.footerLinkItem {
    display: inline-block;
    padding: 10px 35px;
    font-weight: bold;
}
.socials {
    float: right;
    padding: 20px 0;
}
.socialsMargin {
    margin: 0 -20px;
}
.socialItem {
    padding: 10px 20px;
    display: inline-block;
}
.socialItem a:hover,
.footerLinkItem a:hover,
.iconText:hover {
    color: var(--button-bg);
}
.subscribe {
    width: 40%;
    max-width: 520px;
}
.form {
    border: 1px solid #FFFFFF;
    border-radius: 50px;
    margin-top: 20px;
    position: relative;
    padding: 0 150px 0 25px;
    text-align: left;
}
.fieldButton {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    height: 100%;
}
.fieldButton > * {
    max-height: 100%;
}
.burger {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    height: 23px;
    width: 30px;
    border: 0px;
    padding: 0px;
    background: transparent;
    display: none;
}
.burger:before, .burger:after {
    content: '';
    width: 100%;
    right: 0;
    height: 2px;
    position: absolute;
    background: #fff;
    transition: all 250ms ease-out;
    will-change: transform;
}
.burger:before {
    transform: translateY(-7px);
}
.burger:after {
    transform: translateY(6px);
}
.active.burger:before {
    transform: translateY(0) rotate(45deg);
}
.active.burger:after {
    transform: translateY(0) rotate(-45deg);
}
.mobileMenu {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
}
.mobileMenu > div {
    background-color: var(--layout-bg);
    color: #fff;
}
.mobileLinkItem > * {
    padding: 12px;
    display: block;
    font-weight: bold;
    font-size: 17px;
}
.mobileLinkItem.hasSubMenu > * {
    display: inline-block;
    vertical-align: middle;
}
.mobileLinkItem.hasSubMenu a {
    width: 100%;
    width: calc(100% - 50px);
}
.mobileLinkItem.hasSubMenu button {
    width: 50px;
}
.mobileLinkItem>button>* {
    vertical-align: middle;
    display: inline-block;
}
.menuMargin {
    margin: 10px -10px;
}
.mobileDropdownMenuLinks {
    padding: 0 0 10px 0;
    margin-top: -5px;
    font-family: 'FS Kim';
}
.mobileDropdownMobileLinkItem {
    padding: 10px;
}
@media (max-width: 1400px) {
    .subscribe {
        flex: 1;
        width: auto;
    }
    .title {
        font-size: 26px;
    }
    .footerWrap {
        padding: 75px 0;
    }
    .dropdownMenuContent {
        max-width: 500px;
        width: 40%;
    }
    .dropdownMenuLinks {
        font-size: 32px;
    }
    .dropdownMenuLinkItem {
        padding-right: 100px;
    }
    .arrow {
        right: 20px;
    }
    .arrow::before {
        width: 60px;
    }
}
@media (max-width: 1200px) {
    .subscribe {
        display: block;
        flex: none;
        padding: 60px 0;
        max-width: 650px;
    }
    .dropdownMenuLinks {
        font-size: 30px;
    }
}
@media (max-width: 1024px) {
    .title {
        font-size: 24px;
    }
    .footerWrap {
        padding: 60px 0;
    }
    .dropdownMenu {
        padding: 30px 0 60px;
    }
}
@media (max-width: 950px) {
    .dropdownMenuContent {
        display: none;
    }
    .dropdownMenu {
        padding: 10px 0 30px;
    }
    .dropdownMenuLinks {
        padding-left: 0;
    }
}
@media (max-width: 800px) {
    .header {
        background-color: var(--header-bg);
    }
    .headerLinks {
        display: none;
    }
    .logo {
        padding: 20px 0;
    }
    .logo img {
        height: 16px;
    }
    .mobileMenu,
    .burger {
        display: block;
    }
    .locations {
        white-space: normal;
        margin-top: -40px;
    }
    .location {
        margin-top: 40px;
        padding-right: 0;
    }
    .contact {
        padding-right: 20px;
    }
    .footerWrap {
        padding: 40px 0;
    }
    .subscribe {
        text-align: center;
        padding: 40px 0;
    }
    .title {
        font-size: 22px;
    }
    .headerCenter {
        text-align: center;
    }
}
@media (max-width: 720px) {
    .location {
        display: block;
    }
    .locations {
        flex: 2;
    }
    .contact {
        flex: 1;
    }
    .footerLinks, .socials {
        float: none;
        text-align: center;
    }
    .links::after {
        display: none;
    }
    .footerLinks {
        padding-bottom: 0;
    }
    .footerLinksMargin {
        margin: 0 -20px;
    }
    .footerLinkItem {
        padding: 10px 20px;
    }
    .footer {
        font-size: 15px;
    }
}
@media (max-width: 550px) {
    .footer {
        font-size: 14px;
    }
    .locations {
        display: none;
    }
    .title {
        font-size: 21px;
    }
    .socials {
        padding-top: 10px;
    }
}
@media (max-width: 350px) {
    .headerCenter {
        text-align: left;
    }
}