.modal,
.dialogBoxOverlayWrap,
.dialogBoxOverlay {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.modal {
    position: fixed;
    z-index: 99999;
}
.dialogBoxOverlayWrap {
    transition: opacity 0.6s ease-in;
    opacity: 0;
    background: #fff;
    color: #0F2846;
    position: absolute;
}
.dialogBoxOverlay {
    position: absolute;
    overflow: auto;
    z-index: 1;
}
.dialogOpen {
    opacity: 1;
}
.dialogRemove {
    opacity: 0;
    transition: opacity 0.6s ease-out;
}
.dialogClose {
    position: absolute;
    right: 10px;
    top: 5px;
    padding: 15px 20px;
    background-color: transparent;
    z-index: 2;
}
.closeIcon {
    font-size: 24px;
    line-height: 1;
    font-weight: 600;
}
.closeText {
    font-size: 16px;
    font-weight: bold;
    display: inline-block;
    position: relative;
    top: 2px;
}
.dialogClose:hover {
    color: #000
}
.dialogContent {
    position: relative;
}
@media (max-width: 550px) {
    .closeText {
        display: none;
    }
    .dialogClose {
        right: 0;
        background-color: #fff;
        top: 0;
    }
    .col {
        display: block;
        width: auto;
    }
}