@font-face {
    font-family: 'FS Kim';
    src: url('../fonts/FS Kim/fskim.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FS Kim';
    src: url('../fonts/FS Kim/fskim_medium.woff') format('woff');
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'Acumin Pro';
    src: local('Acumin Pro Bold Italic'), local('AcuminPro-BoldItalic'),
        url('../fonts/AcuminPro/AcuminPro-BoldItalic.woff2') format('woff2'),
        url('../fonts/AcuminPro/AcuminPro-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Acumin Pro';
    src: local('Acumin Pro Black'), local('AcuminPro-Black'),
        url('../fonts/AcuminPro/AcuminPro-Black.woff2') format('woff2'),
        url('../fonts/AcuminPro/AcuminPro-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Acumin Pro';
    src: local('Acumin Pro Black Italic'), local('AcuminPro-BlackItalic'),
        url('../fonts/AcuminPro/AcuminPro-BlackItalic.woff2') format('woff2'),
        url('../fonts/AcuminPro/AcuminPro-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Acumin Pro';
    src: local('Acumin Pro Extra Light'), local('AcuminPro-ExtraLight'),
        url('../fonts/AcuminPro/AcuminPro-ExtraLight.woff2') format('woff2'),
        url('../fonts/AcuminPro/AcuminPro-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Acumin Pro';
    src: local('Acumin Pro Bold'), local('AcuminPro-Bold'),
        url('../fonts/AcuminPro/AcuminPro-Bold.woff2') format('woff2'),
        url('../fonts/AcuminPro/AcuminPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Acumin Pro';
    src: local('Acumin Pro Light'), local('AcuminPro-Light'),
        url('../fonts/AcuminPro/AcuminPro-Light.woff2') format('woff2'),
        url('../fonts/AcuminPro/AcuminPro-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Acumin Pro';
    src: local('Acumin Pro Italic'), local('AcuminPro-Italic'),
        url('../fonts/AcuminPro/AcuminPro-Italic.woff2') format('woff2'),
        url('../fonts/AcuminPro/AcuminPro-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Acumin Pro';
    src: local('Acumin Pro Extra Light Italic'), local('AcuminPro-ExtraLightItalic'),
        url('../fonts/AcuminPro/AcuminPro-ExtraLightItalic.woff2') format('woff2'),
        url('../fonts/AcuminPro/AcuminPro-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Acumin Pro';
    src: local('Acumin Pro Light Italic'), local('AcuminPro-LightItalic'),
        url('../fonts/AcuminPro/AcuminPro-LightItalic.woff2') format('woff2'),
        url('../fonts/AcuminPro/AcuminPro-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Acumin Pro';
    src: local('Acumin Pro Semibold Italic'), local('AcuminPro-SemiboldItalic'),
        url('../fonts/AcuminPro/AcuminPro-SemiboldItalic.woff2') format('woff2'),
        url('../fonts/AcuminPro/AcuminPro-SemiboldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Acumin Pro';
    src: local('Acumin Pro'), local('AcuminPro-Regular'),
        url('../fonts/AcuminPro/AcuminPro-Regular.woff2') format('woff2'),
        url('../fonts/AcuminPro/AcuminPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Acumin Pro';
    src: local('Acumin Pro Thin'), local('AcuminPro-Thin'),
        url('../fonts/AcuminPro/AcuminPro-Thin.woff2') format('woff2'),
        url('../fonts/AcuminPro/AcuminPro-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Acumin Pro';
    src: local('Acumin Pro Semibold'), local('AcuminPro-Semibold'),
        url('../fonts/AcuminPro/AcuminPro-Semibold.woff2') format('woff2'),
        url('../fonts/AcuminPro/AcuminPro-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Acumin Pro';
    src: local('Acumin Pro Medium'), local('AcuminPro-Medium'),
        url('../fonts/AcuminPro/AcuminPro-Medium.woff2') format('woff2'),
        url('../fonts/AcuminPro/AcuminPro-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Acumin Pro';
    src: local('Acumin Pro Medium Italic'), local('AcuminPro-MediumItalic'),
        url('../fonts/AcuminPro/AcuminPro-MediumItalic.woff2') format('woff2'),
        url('../fonts/AcuminPro/AcuminPro-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Acumin Pro';
    src: local('Acumin Pro Thin Italic'), local('AcuminPro-ThinItalic'),
        url('../fonts/AcuminPro/AcuminPro-ThinItalic.woff2') format('woff2'),
        url('../fonts/AcuminPro/AcuminPro-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Acumin Pro';
    src: local('Acumin Pro Ultra Black'), local('AcuminPro-UltraBlack'),
        url('../fonts/AcuminPro/AcuminPro-UltraBlack.woff2') format('woff2'),
        url('../fonts/AcuminPro/AcuminPro-UltraBlack.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Acumin Pro';
    src: local('Acumin Pro Ultra Black Italic'), local('AcuminPro-UltraBlackItalic'),
        url('../fonts/AcuminPro/AcuminPro-UltraBlackItalic.woff2') format('woff2'),
        url('../fonts/AcuminPro/AcuminPro-UltraBlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

