.formGroup {
    margin-top: 20px;
    border-bottom: 1px solid var(--input-border);
    position: relative;
    transition: border-color 0.4s;
}
.inputError {
    border-color: var(--input-error);
}
.inputFocus {
    border-color: var(--input-focus);
}
.textbox {
    color: inherit;
    padding: 27px 0 8px;
    width: 100%;
    background: transparent;
    position: relative;
    z-index: 2;
    text-align: left;
    border: 0;
}
textarea.textbox {
    height: 150px;
}
.controlLabel {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    color: var(--input-placeholder);
    padding: 26px 0px 0;
    background: transparent;
    transition: padding 0.4s, font-size 0.4s;
    text-align: left;
}
.controlLabel.focus {
    padding-top: 8px;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.085em;
}
.noBorder {
    border: 0;
    margin: 0;
}
.noBorder .textbox {
    padding: 27px 0 13px;
}
.noBorder .controlLabel {
    padding-top: 22px;
}
.noBorder .controlLabel.focus {
    padding-top: 12px;
}
.noBorder .helpBlock {
    top: 100%;
    bottom: auto;
    left: 0;
}
.noLabel .textbox {
    padding: 8px 0px;
}
.inputPhone {
    padding-bottom: 8px;
}
.inputPhone .controlLabel {
    position: static;
    padding-bottom: 10px;
}
.selectBox {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 5;
    padding: 10px 15px;
}
.selectDisabled {
    opacity: 0.3;
}
.inputFetching .textbox {
    padding: 15px;
}
.inputFetchingLabel + div {
    transform: translateY(-50%);
    right: 15px;
    margin: 0;
    left: auto;
}
.checkbox {
    cursor: pointer;
}
.checkbox:hover {
    color: var(--link-hover);
}
.checkbox > * {
    display: inline-block;
    vertical-align: middle;
}
.checkboxSquare {
    display: inline-block;
    margin-right: 15px;
    width: 24px;
    height: 24px;
    border: 1px solid var(--checkbox-border);
    position: relative;
    border-radius: 5px;
    transition: background-color 0.4s;
}
.checkboxText > * {
    vertical-align: middle;
}
.checkboxInput:checked + .checkboxSquare {
    background-color: var(--checkbox-border);
}
.checkboxInput + .checkboxSquare::before {
    content: '';
    display: block;
    z-index: 4;
    width: 12px;
    height: 5px;
    border-left: 2px solid var(--button-text);
    border-bottom: 2px solid var(--button-text);
    transform: translate(-50%, -68%) rotate(-45deg);
    position: absolute;
    top: 50%;
    left: 50%;
    transition: opacity 0.4s;
}
.checkboxInput:checked + .checkboxSquare::before {
    opacity: 1;
}
.button {
    display: inline-block;
    text-align: center;
    position: relative;
    border: 1px solid;
    padding: 17px 35px 15px;
    line-height: 1;
    border-radius: 40px;
    font-weight: 700;
    background-color: var(--button-bg);
    border-color: var(--button-bg);
    color: var(--button-text);
    letter-spacing: 0.0125em;
    font-family: 'Acumin Pro';
}
.button.buttonFull {
    width: 100%;
}
.button:not(.buttonDisabled):hover {
    background-color: transparent;
    color: var(--button-bg);
}
.buttonWhite {
    background-color: #fff;
    color: #082535;
    border-color: #fff;
}
.buttonWhite:not(.buttonDisabled):hover {
    background-color: transparent;
    color: #fff;
}
.buttonDisabled {
    opacity: 0.5;
}
.buttonDisabled > span {
    vertical-align: middle;
}
.buttonQuestion {
    margin-left: 5px;
}
.required {
    color: inherit;
}
.helpBlock {
    color: var(--input-error);
    position: absolute;
    right: 0;
    font-size: 13px;
    z-index: 4;
    white-space: nowrap;
    bottom: 0px;
    padding: 10px 0;
    line-height: 1;
}
.searchIcon{
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    color: var(--common-orange);
}
.dropdown .textbox span {
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
}
.dropdown .textbox span:nth-child(2) {
    margin-top: -5px;
    color: var(--common-orange);
}
.filterInput {
    margin-top: 0;
}
.filterInput .textbox span:nth-child(2){
    padding-left: 15px;
}
.filterInput.formGroup {
    border: none;
}
.filterInput.formGroup .textbox, .filterInput .selectBox {
    padding: 0;
}
.filterInput input {
    min-width: 300px;
}
.filterInput .textbox::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: var(--common-text-black);
}
.filterInput .textbox::-moz-placeholder {
    /* Firefox 19+ */
    color: var(--common-text-black);
}
.filterInput .textbox:-ms-input-placeholder {
    /* IE 10+ */
    color: var(--common-text-black);
}
.filterInput .textbox:-moz-placeholder {
    /* Firefox 18- */
    color: var(--common-text-black);
}
@media (max-width: 1024px){
    .filterInput input {
        min-width: 0;
    }
}