.swiper-wrapper {
    align-items: center;
}
.image_text_slider .swiper-wrapper {
    align-items: flex-start;
}
.text_slider .swiper-button-next, .text_slider .swiper-button-prev {
    visibility: hidden;
}
.swiper-pagination {
    text-align: center;
    transition: 0.3s opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;
    display: inline-block;
    white-space: nowrap;
    position: relative;
    height: 38px;
}
.swiper-pagination::before {
    content: "";
    position: absolute;
    left: 5px;
    top: 50%;
    right: 5px;
    height: 1px;
    background-color: #1D1D1D;
}
.swiper-pagination.swiper-pagination-hidden {
    opacity: 0;
}
.swiper-pagination-disabled > .swiper-pagination,
.swiper-pagination.swiper-pagination-disabled {
    display: none !important;
}
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
    margin-top: 50px;
}
.swiper-pagination-bullets-dynamic {
    overflow: hidden;
    font-size: 0;
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    transform: scale(0.33);
    position: relative;
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active {
    transform: scale(1);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
    transform: scale(1);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
    transform: scale(0.66);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
    transform: scale(0.33);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
    transform: scale(0.66);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
    transform: scale(0.33);
}
.swiper-pagination-bullet {
    width: 15px;
    height: 14px;
    display: inline-block;
    border-radius: 9px;
    padding: 2px;
    background: transparent;
    border: 1px solid #1D1D1D;
    vertical-align: middle;
    position: relative;
    background-color: #F1F1F1;
    transition: all 0.4s;
}
button.swiper-pagination-bullet {
    border: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    -webkit-appearance: none;
    appearance: none;
}
.swiper-pagination-clickable .swiper-pagination-bullet {
    cursor: pointer;
}
.swiper-pagination-bullet:only-child {
    display: none !important;
}
.swiper-pagination-bullet-active {
    border-radius: 5px;
    height: 38px;
    padding: 2px;
}
.swiper-pagination-bullet-active::after {
    content: "";
    display: block;
    height: 100%;
    border-radius: 3px;
    transition: background-color 0.4s;
}
.swiper-pagination-bullet-active::after {
    background-color: #FF7D4B;
}
.swiper-pagination-vertical.swiper-pagination-bullets,
.swiper-vertical > .swiper-pagination-bullets {
    right: 10px;
    top: 50%;
    transform: translate3d(0px, -50%, 0);
}
.swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: var(--swiper-pagination-bullet-vertical-gap, 6px) 0;
    display: block;
}
.swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
.swiper-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
}
.swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet,
.swiper-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    display: inline-block;
    transition: 0.2s transform, 0.2s top;
}
.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
}
.swiper-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
.swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
}
.swiper-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    transition: 0.2s transform, 0.2s left;
}
.swiper-horizontal.swiper-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    transition: 0.2s transform, 0.2s right;
}
.swiper-pagination-progressbar {
    background: rgba(0, 0, 0, 0.25);
    position: absolute;
}
.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background: var(--swiper-pagination-color, var(--swiper-theme-color));
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: scale(0);
    transform-origin: left top;
}
.swiper-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    transform-origin: right top;
}
.swiper-horizontal > .swiper-pagination-progressbar,
.swiper-pagination-progressbar.swiper-pagination-horizontal,
.swiper-pagination-progressbar.swiper-pagination-vertical.swiper-pagination-progressbar-opposite,
.swiper-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
    width: 100%;
    height: 4px;
    left: 0;
    top: 0;
}
.swiper-horizontal > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite,
.swiper-pagination-progressbar.swiper-pagination-horizontal.swiper-pagination-progressbar-opposite,
.swiper-pagination-progressbar.swiper-pagination-vertical,
.swiper-vertical > .swiper-pagination-progressbar {
    width: 4px;
    height: 100%;
    left: 0;
    top: 0;
}
.swiper-pagination-lock {
    display: none;
}
.testi_slider .swiper-button-next:after, .team_slider .swiper-button-next:after {
    font-family: 'Arial';
    content: "";
    width: 0; 
    height: 0; 
    --size: 12px;
    --length: 18px;
    --color: #FF7D4B;
    border-top: var(--size) solid transparent;
    border-bottom: var(--size) solid transparent;
    border-left: var(--length) solid var(--color);
}
.testi_slider .swiper-button-prev:after, .team_slider .swiper-button-prev:after {
    font-family: 'Arial';
    content: "";
    width: 0; 
    height: 0; 
    --size: 12px;
    --length: 18px;
    --color: #FF7D4B;
    border-top: var(--size) solid transparent;
    border-bottom: var(--size) solid transparent;
    border-right: var(--length) solid var(--color);
}
.testi_slider .swiper-pagination-bullet, .team_slider .swiper-pagination-bullet {
    background-color: #fff;
    border-color: #FF7D4B;
}
.testi_slider .swiper-pagination-bullet-active::after,
.testi_slider .swiper-pagination::before,
.team_slider .swiper-pagination-bullet-active::after,
.team_slider .swiper-pagination::before {
    background-color: #FF7D4B;
}
.testi_slider .swiper-slide {
    padding: 0 60px;
}