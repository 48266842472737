:root {
    --layout-bg: #0F2846;
    --layout-text: #fff;
    --button-bg: #FF7D4B;
    --button-text: #082535;
    --header-border: transparent;
    --footer-border: rgba(255, 255, 255, 0.6);
    --input-bg: #fff;
    --input-text: #161F64;
    --input-border: #9fa9b6;
    --input-error: #f00;
    --input-focus: #000;
    --header-bg: #2a2a2a;
    --common-orange: #FF7D4B;
    --common-text-black: #1D1D1D;
    --common-text-dark-blue: #0F2846;
    --light-background-color: #F1F1F1;
    --category-background-color: #4D4F7A;
    --white-background: #fff;
}
* {
    margin: 0px;
    padding: 0px;
    vertical-align: top;    
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
html, body {
    background: var(--layout-bg);
    transition: background-color 0.4s ease-in-out;
    color: var(--layout-text);
    scroll-behavior: smooth;
}
body, input, textarea, select {
    font-family: 'Acumin Pro', Arial, Helvetica, sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}
input[type="checkbox"], input[type="radio"] {
    display: none;
}
img {
    max-width: 100%;
    border-style: none;
}
p {
    line-height: 1.65;
}
label {
    cursor: pointer;
}
a, button {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    transition: all 0.4s ease;
    font-family: inherit;
    font-size: inherit;
    background-color: transparent;
}
a:active {
    background-color: transparent;
}
select, map, area, a, button {
    border: 0px;
    outline: none;
}
h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
}
input[type=text], input[type=password], textarea {
    text-align: left;
}
input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
}
ul, li {
    list-style: none;
}
select::-ms-expand {
    display: none;
}
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
input::-ms-clear {
    display: none;
}
input[type="password"]::-ms-clear, input[type="password"]::-ms-reveal {
    display: none;
}
input, textarea, select {
    border: 0;
    outline: none;
    line-height: normal;
    -webkit-appearance: none;
    border-radius: 0;
    color: var(--input-text);
}
textarea {
    resize: none;
}
body>iframe {
    display: none !important;
}
.darkTheme img[data-theme="light"],
.lightTheme img[data-theme="dark"] {
    display: none;
}
.section_title {
    font-size: 58px;
    letter-spacing: -0.01em;
    font-family: 'FS Kim';
    line-height: 1;
    font-weight: 500;
}
.section_text {
    font-size: 28px;
    letter-spacing: 0.0025em;
    margin-top: 20px;
    line-height: 1.4;
}

.banner_link {
    --color: #FF7D4B;
    margin-left: 30px;
    font-weight: bold;
    letter-spacing: 0.0125em;
    color: var(--color);
    border-bottom: 1px solid transparent;
    display: inline-block;
}
.banner_link:hover {
    border-bottom-color: var(--color);
}
.banner_arrow {
    width: 0; 
    height: 0; 
    --size: 6px;
    position: relative;
    top: 1px;
    display: inline-block;
    vertical-align: baseline;
    border-top: var(--size) solid transparent;
    border-bottom: var(--size) solid transparent;
    border-left: var(--size) solid var(--color);
    margin-left: 10px;
}
.banner_link.white {
    --linkwhitecolor: #fff;
    color: var(--linkwhitecolor);
}
.banner_link.white .banner_arrow {
    border-left: var(--size) solid var(--linkwhitecolor);
}
.banner_link.white:hover {
    border-bottom-color: var(--linkwhitecolor);
}

.section_inner_pad {
    padding: 150px 0;
}

.banner_button {
    margin-top: 40px;
}

.banner_button:empty {
    display: none;
}

.banner_button>* {
    vertical-align: middle;
}

.blog_category {
    background-color: var(--category-background-color);
    color: var(--layout-text);
    padding: 10px 15px 8px;
    display: inline-block;
    border-radius: 120px;
    font-size: 14px;
    text-align: center;
}

.blog_list_items {
    margin-left: -15px;
    margin-right: -15px;
}

.blog_list_items .blog_item {
    width: 25%;
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 60px;
}

.section_white_small_padding {
    padding-top: 60px;
    padding-bottom: 60px;
}

.section_white_big_padding{
    padding-top: 100px;
    padding-bottom: 100px;
}

.section_white+.section_white {
    padding-top: 0;
}

.section_white.section_white_big_padding+div:not(.section_white){
    margin-top: -100px;
}

.section_white.section_white_small_padding+div:not(.section_white){
    margin-top: -60px;
}

.video_popup .dialogContent {
    position: relative;
    width: 100%;
    height: 100%;
}
.video_popup iframe {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
}
/* == Toastify == */
.Toastify__toast--default {
    background: var(--layout-bg);
    color: var(--input-bg);
    font-size: 16px;
    padding: 15px;
    font-family: 'Acumin Pro', Arial, Helvetica, sans-serif;
}

.Toastify__toast-container--top-right {
    top: 100px;
    right: 10px;
}

.Toastify__close-button--default {
    color: var(--input-bg);
    opacity: 1;
}

body .Toastify__toast-container {
    z-index: 100000;
}

.Toastify__close-button--light {
    color: var(--input-bg);
}

/* == Toastify == */

@media (max-width: 1600px) {
    .section_title {
        font-size: 56px;
    }
    .section_text {
        font-size: 26px;
    }
    .section_inner_pad {
        padding: 125px 0;
    }
    .blog_category {
        padding: 8px 10px 6px;
        font-size: 13px;
    }
}
@media (max-width: 1400px) {
    .section_title {
        font-size: 48px;
    }
    .section_text {
        font-size: 24px;
    }
    .sectionCenter {
        max-width: 900px;
    }
    .section_inner_pad {
        padding: 100px 0;
    }
    .blog_list_items .blog_item {
        width: 33.33%;
    }
    .section_white_big_padding{
        padding-top: 75px;
        padding-bottom: 75px;
    }
    .section_white.section_white_big_padding+div:not(.section_white){
        margin-top: -75px;
    }
}
@media (max-width: 1200px) {
    .section_title {
        font-size: 42px;
    }
    .banner_button {
        margin-top: 35px;
    }
}
@media (max-width: 1024px) {
    .section_title {
        font-size: 39px;
    }
    .sectionCenter {
        max-width: 700px;
    }
    .section_text {
        font-size: 22px;
    }
    .section_inner_pad {
        padding: 90px 0;
    }
    .blog_category {
        font-size: 12px;
    }
}
@media (max-width: 900px) {
    .banner_button {
        margin-top: 30px;
    }
}
@media (max-width: 800px) {
    .section_title {
        font-size: 36px;
    }
    .section_text {
        font-size: 21px;
    }
    .testi_slider .swiper-slide {
        padding: 0 !important;
    }
    .swiper-button-prev, .swiper-button-next {
        display: none !important;
    }
    .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
        margin-top: 30px !important;
    }
    .blog_list_items .blog_item {
        width: 50%;
    }
    .section_white_big_padding{
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .section_white.section_white_big_padding+div:not(.section_white){
        margin-top: -60px;
    }
}
@media (max-width: 550px) {
    .section_title {
        font-size: 33px;
    }
    .section_text {
        font-size: 20px;
    }
    .banner_link {
        display: none;
    }
    .section_inner_pad {
        padding: 75px 0;
    }
    .section_white_small_padding{
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .section_inner_pad {
        min-height: 0 !important;
    }
    .blog_list_items .blog_item {
        padding-top: 40px;
    }
    .blog_list_items .blog_item {
        width: 100%;
    }
    .section_white_big_padding{
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .section_white.section_white_big_padding+div:not(.section_white){
        margin-top: -50px;
    }
    .section_white.section_white_small_padding+div:not(.section_white){
        margin-top: -40px;
    }
}
@media (max-width: 400px) {
    .section_title {
        font-size: 30px;
    }
    .section_inner_pad {
        padding: 60px 0;
    }
}